import React, { useContext } from "react";

import {
	makeStyles,
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";

import { Helmet } from "react-helmet";

import { DarkModeContext, Footer } from "@runly/gatsby-theme";
import AppNavBar from "./navbar";

const Layout = ({
	location,
	isOnHero,
	isLandingPage,
	maxWidth = "lg",
	disableNavbarMargin,
	disableFooterMargin,
	showNavLinks = true,
	appBarClassName = "",
	footerClassName = "",
	children
}) => {
	const { darkMode } = useContext(DarkModeContext);
	const classes = useStyles({
		darkMode,
		isOnHero,
		disableNavbarMargin,
		disableFooterMargin
	});

	return (
		<ThemeProvider
			theme={({ overrides, ...theme }) =>
				createMuiTheme({
					...theme,
					overrides: { ...overrides, MuiHidden: { implementation: "css" } }
				})
			}
		>
			<div className={classes.layout}>
				<Helmet titleTemplate="%s - Runly" />
				<AppNavBar
					{...{
						appBarClassName,
						location,
						isOnHero,
						isLandingPage,
						maxWidth,
						showNavLinks
					}}
				/>

				<main className={classes.mainContents}>{children}</main>

				<Footer
					maxWidth={maxWidth}
					className={`${classes.footer} ${footerClassName}`}
				/>
			</div>
		</ThemeProvider>
	);
};

const useStyles = makeStyles(theme => ({
	"@global": {
		"*": {
			transition: theme.transitions.create(["background-color", "border-color"])
		},
		html: {
			scrollBehavior: "smooth",
			"@media screen and (prefers-reduced-motion: reduce)": {
				scrollBehavior: "auto"
			}
		},
		body: {
			backgroundColor: theme.palette.background.default
		}
	},
	layout: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh"
	},
	mainContents: {
		flexGrow: 1,
		flexShrink: 0,
		overflow: "hidden",
		marginTop: ({ isOnHero, disableNavbarMargin }) =>
			isOnHero || disableNavbarMargin ? 0 : theme.spacing(6),

		[theme.breakpoints.down("sm")]: {
			marginTop: ({ isOnHero }) => (!isOnHero ? theme.spacing(12) : 0)
		}
	},
	footer: {
		marginTop: ({ disableFooterMargin }) =>
			!disableFooterMargin ? theme.spacing(6) : 0
	}
}));

export default Layout;
