import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, RUNLY_DARK_BLUE } from "@runly/gatsby-theme";
import { Box, Button } from "@material-ui/core";
import tc from "tinycolor2";

export const NavLink = ({
	isUserLink,
	children,
	isOnHero,
	darkMode,
	location,
	...props
}) => {
	const isActive = location && location.pathname.startsWith(props.to);
	const classes = useNavLinkStyles({ isOnHero, darkMode, isActive });
	return isUserLink ? (
		<Box px={1.5} display="flex" alignItems="center">
			<Button
				variant="contained"
				color="primary"
				component={Link}
				{...props}
				className={classes.userLink}
			>
				{children}
			</Button>
		</Box>
	) : (
		<Link color="textPrimary" className={classes.tabLink} {...props}>
			{children}
		</Link>
	);
};
const useNavLinkStyles = makeStyles(theme => {
	const linkBase = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: theme.palette.getContrastText(theme.palette.primary.dark),
		backgroundColor: ({ isOnHero, isActive }) =>
			isActive
				? isOnHero
					? "rgba(255,255,255,0.25)"
					: theme.palette.divider
				: null,
		borderBottom: ({ isActive }) => {
			return `${theme.spacing(0.5)}px solid ${
				isActive ? "#fff" : "transprent"
			}`;
		}
	};
	return {
		tabLink: {
			...linkBase,
			...theme.typography.subtitle1,
			fontWeight: theme.typography.h4.fontWeight,
			padding: theme.spacing(1.5, 1.5),
			transition: theme.transitions.create(["background-color"]),
			"&, &:hover, &:focus": { textDecoration: "none" },
			"&:hover, &:focus": {
				backgroundColor: "rgba(255,255,255,0.1)"
			}
		},
		userLink: {
			...theme.typography.subtitle1,
			fontWeight: theme.typography.h4.fontWeight,
			textTransform: "none",
			backgroundColor: RUNLY_DARK_BLUE,
			color: theme.palette.getContrastText(RUNLY_DARK_BLUE),
			"&:hover, &:focus": {
				backgroundColor: tc(RUNLY_DARK_BLUE).darken(5).toString()
			},
			borderRadius: theme.spacing(0.5),
			"&, &:hover, &:focus": {
				boxShadow: "none"
			}
		}
	};
});
