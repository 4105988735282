import React from "react";

import { AppBar, Toolbar, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Logo, RUNLY_APPBAR_GRADIENT } from "@runly/gatsby-theme";
import tc from "tinycolor2";
import { NavLink } from "./nav-link";
import useNavbarLinks from "../links";
import { SKEW } from "../../../constants";
import { DarkModeSwitch } from "../dark-mode-switch";

export const DesktopNavBar = ({
	maxWidth,
	isOnHero,
	isLandingPage,
	showNavLinks,
	appBarClassName,
	...props
}) => {
	const classes = useStyles({ isOnHero, ...props });

	const navbarLinks = useNavbarLinks();

	return (
		<AppBar
			position="static"
			color="default"
			elevation={isOnHero ? 0 : 4}
			className={`${classes.appBar} ${appBarClassName}`}
		>
			<Container maxWidth={maxWidth}>
				<div style={{ position: "relative" }}>
					{isLandingPage ? (
						<div
							style={{
								background: `linear-gradient(81deg, ${tc("#1b1464")
									.setAlpha(0)
									.toString()} 10%, 30%, ${"#1b1464"} 43%)`,
								opacity: 0.6,
								height: 160,
								width: 1200,
								position: "absolute",
								bottom: -145,
								left: -660,
								transformOrigin: "top left",
								transform: `skewY(${SKEW})`
							}}
						/>
					) : null}
				</div>

				<Toolbar disableGutters className={classes.toolbar}>
					<Link
						variant="h6"
						color="inherit"
						noWrap
						to="/"
						className={classes.toolbarTitle}
					>
						<Logo className={classes.logo} />
					</Link>

					{showNavLinks ? (
						<>
							<nav className={classes.tabLinks}>
								{navbarLinks.map(({ label, href, ...linkProps }) => (
									<NavLink key={label} to={href} {...props} {...linkProps}>
										{label}
									</NavLink>
								))}
							</nav>
							<DarkModeSwitch />
						</>
					) : null}
				</Toolbar>
			</Container>
		</AppBar>
	);
};
const useStyles = makeStyles(theme => {
	return {
		appBar: {
			background: ({ isOnHero }) =>
				isOnHero ? "transparent" : RUNLY_APPBAR_GRADIENT,
			position: ({ isOnHero }) => (isOnHero ? "absolute" : null)
		},
		logo: {
			padding: 4,
			width: 120
		},
		toolbar: {
			flexWrap: "wrap"
		},
		toolbarTitle: {
			flexGrow: 1,
			color: ({ isOnHero }) => (isOnHero ? theme.palette.common.white : null),
			fontWeight: theme.typography.fontWeightBold
		},
		tabLinks: {
			display: "flex",
			margin: theme.spacing(0, 1.5)
		},
		darkModeSwitchIcon: {
			color: theme.palette.getContrastText(theme.palette.primary.dark),
			transition: "350ms ease-out",
			transform: ({ darkMode }) => `rotate(${darkMode ? "-" : ""}360deg)`
		}
	};
});
