import React from "react";

import MobileFirstWrapper from "../../mobile-or-desktop";
import { DesktopNavBar } from "./desktop";
import { MobileNavBar } from "./mobile";

const AppNavBar = props => {
	return (
		<MobileFirstWrapper desktop={<DesktopNavBar {...props} />}>
			<MobileNavBar {...props} />
		</MobileFirstWrapper>
	);
};

export default AppNavBar;
