import React, { useState, useEffect } from "react";

import { Hidden } from "@material-ui/core";

// Opt-in to desktop content.
// Use CSS implementation at first to avoid ssr FOUC,
// then JS implementation after mount to clean up DOM.

const MobileFirstWrapper = ({ children, desktop }) => {
	const [implementation, setImplementation] = useState("css");
	useEffect(() => {
		setImplementation("js");
	}, []);
	if (desktop && !children) {
		console.warn(
			"Will fall back to desktop contents as no mobile children were provided"
		);
		return desktop;
	}

	if (children && !desktop) return children;

	return (
		<>
			<Hidden implementation={implementation} mdUp>
				{children}
			</Hidden>
			<Hidden implementation={implementation} smDown>
				{desktop}
			</Hidden>
		</>
	);
};

export default MobileFirstWrapper;
