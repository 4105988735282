import React, { useState, useCallback, useEffect } from "react";
import {
	Logo,
	Link,
	RUNLY_APPBAR_GRADIENT_L,
	RUNLY_APPBAR_GRADIENT
} from "@runly/gatsby-theme";
import {
	AppBar,
	Toolbar,
	Box,
	IconButton,
	RootRef,
	SwipeableDrawer,
	List,
	ListItem,
	ListItemText,
	useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from "@material-ui/icons";
import useNavbarLinks from "../links";
import tc from "tinycolor2";
import { DarkModeSwitch } from "../dark-mode-switch";

let RefableLink = (props, ref) => (
	<RootRef rootRef={ref}>
		<Link {...props} />
	</RootRef>
);

RefableLink = React.forwardRef(RefableLink);

const useStyles = makeStyles(theme => {
	const backgroundColor = RUNLY_APPBAR_GRADIENT_L;
	const foregroundColor = "#fff";

	const toolbarHeight = theme.mixins.toolbar.minHeight;
	return {
		"@global": {
			main: {
				transition: ({ isOpen }) =>
					theme.transitions.create(["transform"], {
						duration:
							theme.transitions.duration[
								isOpen ? "enteringScreen" : "leavingScreen"
							]
					})
			}
		},
		appBar: {
			background: RUNLY_APPBAR_GRADIENT,
			minHeight: toolbarHeight,
			color: foregroundColor
		},
		logo: {
			height: theme.mixins.toolbar.minHeight,
			width: "15%",
			minWidth: 120
		},
		drawer: {
			top: toolbarHeight,
			height: `calc(100vh - ${toolbarHeight}px)`,
			backgroundImage: [
				`linear-gradient(to bottom, ${tc(backgroundColor)
					.setAlpha(0.8)
					.toString()}, 70%, ${tc(backgroundColor).setAlpha(0).toString()})`,
				`linear-gradient(to right, ${tc(backgroundColor)
					.darken(20)
					.toString()}, 70%, ${backgroundColor})`
			]
		},
		backdrop: {
			paddingTop: toolbarHeight,
			backgroundClip: "content-box"
		},
		drawerList: {
			display: "flex",
			flexDirection: "column",
			minWidth: "80vw",
			flexGrow: 1,
			flexShrink: 1,
			background: "transparent",

			color: foregroundColor
		},
		bodyDrawerOpen: {
			transform: "translateX(80vw)"
		},
		linkText: {
			...theme.typography.h1,
			fontSize: theme.typography.h2.fontSize
		}
	};
});

export const MobileNavBar = ({ showNavLinks }) => {
	const navbarLinks = useNavbarLinks();

	const [isOpen, setIsOpen] = useState(false);

	const onOpen = useCallback(() => {
		setIsOpen(true);
	}, []);
	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);
	const onToggle = useCallback(() => {
		setIsOpen(o => !o);
	}, []);

	const onLinkClick = useCallback(() => {
		onClose();
		return true;
	}, [onClose]);

	const theme = useTheme();
	const shouldClose = useMediaQuery(theme.breakpoints.up("md"));

	useEffect(() => {
		if (shouldClose) {
			onClose();
		}
	}, [onClose, shouldClose]);

	const classes = useStyles({ isOpen });

	useEffect(() => {
		document
			.querySelector("main")
			.classList[isOpen ? "add" : "remove"](classes.bodyDrawerOpen);
	}, [classes.bodyDrawerOpen, isOpen]);

	return (
		<>
			<AppBar
				position="fixed"
				color="primary"
				className={classes.appBar}
				elevation={isOpen ? 0 : 4}
			>
				<Toolbar>
					<Link display="block" color="inherit" noWrap to="/">
						<Logo className={classes.logo} />
					</Link>
					{showNavLinks ? (
						<Box style={{ marginLeft: "auto" }}>
							<IconButton color="inherit" onClick={onToggle}>
								{isOpen ? <MenuOpenIcon /> : <MenuIcon />}
							</IconButton>
						</Box>
					) : null}
				</Toolbar>
			</AppBar>
			{showNavLinks ? (
				<SwipeableDrawer
					open={isOpen}
					onOpen={onOpen}
					onClose={onClose}
					PaperProps={{ className: classes.drawer }}
					ModalProps={{ BackdropProps: { className: classes.backdrop } }}
				>
					<List className={classes.drawerList}>
						{navbarLinks.map(({ label, href }) => (
							<ListItem
								onClick={onLinkClick}
								key={label}
								component={RefableLink}
								to={href}
								underline="none"
								color="inherit"
							>
								<ListItemText
									primary={label}
									primaryTypographyProps={{ className: classes.linkText }}
								/>
							</ListItem>
						))}
						<ListItem style={{ marginTop: "auto" }} divider />
						<DarkModeSwitch onClick={onLinkClick} ListItemTextProps={{}} />
					</List>
				</SwipeableDrawer>
			) : null}
		</>
	);
};
