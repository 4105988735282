import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, image, article }) => {
	const { pathname } = useLocation();

	const {
		site: {
			siteMetadata: { siteUrl, canonicalUrl: siteCanonicalUrl, twitter }
		},
		logo
	} = useStaticQuery(graphql`
		query SeoQuery {
			site {
				siteMetadata {
					twitter
					canonicalUrl
					siteUrl
				}
			}
			logo: file(sourceInstanceName: { eq: "logos" }, name: { eq: "arrow" }) {
				arrow: childImageSharp {
					img: fixed(width: 300) {
						src
					}
				}
			}
		}
	`);

	const url = `${siteUrl}${pathname || "/"}`;
	const canonicalUrl = `${siteCanonicalUrl}${pathname || "/"}`;

	const imageUrl = image
		? `${siteUrl}${image}`
		: `${siteUrl}${logo.arrow.img.src}`;
	const isLargeImage = !!image;

	return (
		<Helmet>
			<link rel="canonical" href={canonicalUrl} />

			{title ? <title>{title}</title> : null}
			{description ? <meta name="description" content={description} /> : null}
			{imageUrl ? <meta name="image" content={imageUrl} /> : null}

			{url ? <meta property="og:url" content={url} /> : null}

			<meta property="og:type" content={article ? "article" : "website"} />

			{article && article.published ? (
				<meta property="article:published_time" content={article.published} />
			) : null}
			{article && article.modified ? (
				<meta property="article:modified_time" content={article.modified} />
			) : null}
			{article ? (
				<meta property="article:section" content="Software Development" />
			) : null}

			{imageUrl ? <meta property="og:image" content={imageUrl} /> : null}
			{title ? <meta property="og:title" content={title} /> : null}
			{description ? (
				<meta property="og:description" content={description} />
			) : null}

			<meta
				name="twitter:card"
				content={isLargeImage ? "summary_large_image" : "summary"}
			/>
			{twitter ? <meta name="twitter:creator" content={`@${twitter}`} /> : null}
			{title ? <meta name="twitter:title" content={title} /> : null}
			{description ? (
				<meta name="twitter:description" content={description} />
			) : null}
			{imageUrl ? <meta name="twitter:image" content={imageUrl} /> : null}
		</Helmet>
	);
};

export default Seo;
