import { useIsAuthenticated } from "@runly/ui";

const useNavbarLinks = () => {
	const isAuthenticated = useIsAuthenticated();

	return [
		{ label: "Platform", href: "/platform/" },
		{ label: "UI", href: "/ui/" },
		{ label: "Docs", href: "/docs/" },
		{ label: "Videos", href: "/videos/" },
		{ label: "Pricing", href: "/pricing/" },
		{ label: "About", href: "/about/" },
		{
			isUserLink: true,
			label: isAuthenticated ? "Dashboard" : "Sign In",
			href: "/dashboard/"
		}
	];
};

export default useNavbarLinks;
