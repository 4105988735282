import React, { useContext, useCallback } from "react";
import {
	Tooltip,
	ListItem,
	ListItemSecondaryAction,
	ListItemText
} from "@material-ui/core";
import { DarkModeContext, IconButton } from "@runly/gatsby-theme";
import { Brightness3, Brightness5 } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import MobileFirstWrapper from "../../mobile-or-desktop";

export const DarkModeSwitch = ({
	onClick = Function.prototype,
	ListItemTextProps = {}
}) => {
	const { darkMode, setDarkMode } = useContext(DarkModeContext);
	const classes = useDarkModeSwitchStyles({ darkMode });
	const toggleDarkMode = useCallback(() => setDarkMode(!darkMode), [
		darkMode,
		setDarkMode
	]);

	const handleClick = useCallback(() => {
		toggleDarkMode();
		onClick();
	}, [onClick, toggleDarkMode]);

	const switchButton = (
		<IconButton
			classes={{ root: classes.darkModeSwitchIcon }}
			onClick={handleClick}
		>
			{darkMode ? <Brightness3 /> : <Brightness5 />}
		</IconButton>
	);

	const title = `Toggle dark mode (currently ${darkMode ? "dark" : "light"})`;

	return (
		<MobileFirstWrapper
			desktop={
				<Tooltip title={title}>
					<div>{switchButton}</div>
				</Tooltip>
			}
		>
			<ListItem button onClick={handleClick}>
				<ListItemText
					{...ListItemTextProps}
					primary={<>Switch to {darkMode ? "light" : "dark"} mode </>}
				/>
				<ListItemSecondaryAction>{switchButton}</ListItemSecondaryAction>
			</ListItem>
		</MobileFirstWrapper>
	);
};

const useDarkModeSwitchStyles = makeStyles(theme => {
	return {
		darkModeSwitchIcon: {
			color: theme.palette.getContrastText(theme.palette.primary.dark),
			transition: "350ms ease-out",
			transform: ({ darkMode }) => `rotate(${darkMode ? "-" : ""}360deg)`
		}
	};
});
